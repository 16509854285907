import { Stage, IDP } from 'src/constants/constants';

export function getStage(): Stage {
  // alpha will be replaced during deployment by Katal Deployment Lambda with appropriate stage value.
  const stage = process.env.NODE_ENV !== 'production' ? Stage.DEV : (`alpha` as Stage);
  return stage;
}

/**
 * This method can be used to identify IDP in the future.
 */
export function getIDP(): IDP {
  return IDP.amazonFederate;
}
