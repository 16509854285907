/**
 * This is an adapter to convert a fetch call to Amplify call. This allows page to not have to worry about
 * the actual route of the API.
 */
import { FetchFunc } from '@amzn/aes-maestro-website-core/dist';
import { API } from 'aws-amplify';
import { AMPLIFY_API_NAME } from 'src/constants/constants';

const amplifyMethodMapper: { [key: string]: (...args: any) => any | undefined } = {
  GET: API.get.bind(API),
  POST: API.post.bind(API),
  //TODO: add more methods here if necessary. Check Amplify API interface before adding
};

const amplifyFetch: FetchFunc = async (url, init) => {
  const method: string = init?.method || 'GET';
  const amplifyMethod = amplifyMethodMapper[method];
  if (!amplifyMethod) {
    throw Error(`Amplify Method is not defined for method ${method}.`);
  }
  // We assume that AES only integrates Amplifies with 1 API GW. If this is changing, we will need
  // a way to map a call to an API GW.
  return amplifyMethod(AMPLIFY_API_NAME.CMS, url, init);
};

export default amplifyFetch;
