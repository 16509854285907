export enum Stage {
  DEV = 'dev', // Local development
  ALPHA = 'alpha', // Personal Stack
  BETA = 'beta',
  GAMMA = 'gamma',
  PROD = 'prod',
}

export enum IDP {
  amazonFederate = 'amazonFederate',
}

export enum AMPLIFY_API_NAME {
  CMS = 'CMS',
}
