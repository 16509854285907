import React from 'react';
import { useTranslation } from 'react-i18next';
import './header.scss';

function Header() {
  const { t } = useTranslation();

  return (
    <>
      <header className="aes-header">
        <div className="app-logo">
          <img src="https://d7qzviu3xw2xc.cloudfront.net/alexa/assets/images/Alexa_Logo_RGB_BLUE.png" alt="Alexa" />
          <span className="app-logo-text">{t('enterprise-services-text')}</span>
        </div>
        <div className="user-name">
          <span>RS</span>
        </div>
      </header>
    </>
  );
}

export default Header;
