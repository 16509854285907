import React from 'react';
import ReactDOM from 'react-dom';

import App from '@components/App';
import AppInitWrapper from '@components/AppInitWrapper';
import { AmplifyAuthProvider } from 'src/auth/auth';
import '@amzn/meridian-tokens/base/font/amazon-ember.css';
import './index.scss';
import './i18n';

/**
 * Configure amplify to authenticate user using cognito.
 */
AmplifyAuthProvider.initialize();

// The Suspense component causes React to display the fallback Loader until
// the i18n translation strings have been loaded.
ReactDOM.render(
  <React.StrictMode>
    <AppInitWrapper>
      <React.Suspense fallback={<div />}>
        <App />
      </React.Suspense>
    </AppInitWrapper>
  </React.StrictMode>,
  document.getElementById('root')
);
