import { Stage, IDP } from 'src/constants/constants';
import { getStage } from 'src/utils/utils';

export const API_CONFIG = {
  [Stage.DEV]: {
    cmsURL: 'https://ha4ri6wcs2.execute-api.us-west-2.amazonaws.com/beta',
    cmsRegion: 'us-west-2',
    authConfig: {
      [IDP.amazonFederate]: {
        hostedUIDomain: 'alexa-aes-midway-oidc-beta.auth.us-west-2.amazoncognito.com',
        userPoolId: 'us-west-2_C7Osx5Ft7',
        userPoolWebClientId: '2ef10fq17otjtjd5q2qk14q4us',
        region: 'us-west-2',
        providerName: 'Midway',
        identityPoolId: 'us-west-2:ea2d52bc-9fb7-4798-9f10-4521faf14104',
      },
    },
  },
  [Stage.ALPHA]: {
    cmsURL: 'https://ha4ri6wcs2.execute-api.us-west-2.amazonaws.com/beta',
    cmsRegion: 'us-west-2',
    authConfig: {
      [IDP.amazonFederate]: {
        hostedUIDomain: 'alexa-aes-midway-oidc-beta.auth.us-west-2.amazoncognito.com',
        userPoolId: 'us-west-2_C7Osx5Ft7',
        userPoolWebClientId: '2ef10fq17otjtjd5q2qk14q4us',
        region: 'us-west-2',
        providerName: 'Midway',
        identityPoolId: 'us-west-2:ea2d52bc-9fb7-4798-9f10-4521faf14104',
      },
    },
  },
  [Stage.BETA]: {
    cmsURL: 'https://ha4ri6wcs2.execute-api.us-west-2.amazonaws.com/beta',
    cmsRegion: 'us-west-2',
    authConfig: {
      [IDP.amazonFederate]: {
        hostedUIDomain: 'alexa-aes-midway-oidc-beta.auth.us-west-2.amazoncognito.com',
        userPoolId: 'us-west-2_C7Osx5Ft7',
        userPoolWebClientId: '2ef10fq17otjtjd5q2qk14q4us',
        region: 'us-west-2',
        providerName: 'Midway',
        identityPoolId: 'us-west-2:ea2d52bc-9fb7-4798-9f10-4521faf14104',
      },
    },
  },
  [Stage.GAMMA]: {
    // TODO: update to relevant gamma url's/config once available.
    cmsURL: '',
    cmsRegion: '',
    authConfig: {
      [IDP.amazonFederate]: {
        hostedUIDomain: '',
        userPoolId: '',
        userPoolWebClientId: '',
        region: '',
        providerName: '',
        identityPoolId: '',
      },
    },
  },
  [Stage.PROD]: {
    // TODO: update to relevant prod url's/config once available.
    cmsURL: '',
    cmsRegion: '',
    authConfig: {
      [IDP.amazonFederate]: {
        hostedUIDomain: '',
        userPoolId: '',
        userPoolWebClientId: '',
        region: '',
        providerName: '',
        identityPoolId: '',
      },
    },
  },
};

export function getApiConfig() {
  const stage = getStage();

  return API_CONFIG[stage];
}
