import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API } from 'aws-amplify';
import './main-container.scss';
import { AMPLIFY_API_NAME } from 'src/constants/constants';
import APIContext from '@amzn/aes-maestro-website-core/dist/contexts/APIContext';

function MainContainer() {
  const { t } = useTranslation();
  const apiContext = useContext(APIContext);
  const onClick = () => console.log('Clicked!');
  // Not handling loading state and error state now,
  // since this will be moved to AESMaestroWebsiteCore with react-query setup.
  const [cases, setCases] = useState([]);

  /**
   * This will be moved to search component in AESMaestroWebsiteCore package during actual implementation.
   */
  useEffect(() => {
    apiContext
      .fetch('/v1/cases', { method: 'POST' })
      .then((response) => {
        console.log('API response', response);
        setCases(response.AESCase);
      })
      .catch((error) => {
        console.error('API error', error);
      });
  }, []);

  return (
    <>
      <div className="main-container">
        <div className="header">
          <button onClick={onClick}>{t('create-case-text')}</button>
        </div>
        <div className="cases-table">
          <h2>Cases:</h2>
          {/* this will be updated to actual table component form component library */}
          <table>
            <thead>
              <tr>
                <th>CASE ID</th>
                <th>SUBJECT</th>
                <th>CATEGORY</th>
                <th>CLASSIFICATION</th>
                <th>SEVERITY</th>
                <th>STATUS</th>
                <th>CREATED BY</th>
                <th>LAST UPDATED ON</th>
              </tr>
            </thead>
            <tbody>
              {cases.map(
                ({ caseId, title, caseOwner, classification, lastModifiedDate, status, category, severity }) => (
                  <tr key={caseId}>
                    <td>{caseId}</td>
                    <td>{title}</td>
                    <td>{category}</td>
                    <td>{classification}</td>
                    <td>{severity}</td>
                    <td>{status}</td>
                    <td>{caseOwner}</td>
                    <td>{lastModifiedDate}</td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default MainContainer;
