import React, { useEffect, useState } from 'react';
import { AmplifyAuthProvider } from 'src/auth/auth';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Header from '@components/header/Header';
import amplifyFetch from 'src/utils/amplify-fetch';
import APIContext from '@amzn/aes-maestro-website-core/dist/contexts/APIContext';
import { routes } from '@amzn/aes-maestro-website-core/dist';
import MainContainer from '@components/main-container/MainContainer';

function App() {
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

  useEffect(() => {
    /**
     * Authenticate user.
     */
    async function authenticateUser() {
      const user = await AmplifyAuthProvider.authenticateUser();
      // set user authenticated.
      setIsUserAuthenticated(!!user);
    }

    authenticateUser();
  }, []);

  return (
    <>
      <APIContext.Provider value={{ fetch: amplifyFetch }}>
        {isUserAuthenticated && (
          <>
            <Header />
            {/*<RouterProvider router={createBrowserRouter(routes)} />*/}
            <MainContainer />
          </>
        )}
      </APIContext.Provider>
    </>
  );
}

export default App;
